<template>
  <v-container>
    <!-- Header -->
    <header class="custom-header mb-6">
      <v-row no-gutters>
        <h4>අත්හිටවූ සාමාජිකයින්</h4>
        <v-col cols="12" md="3" sm="6">
          <v-btn
            @click="this.clarFilters"
            class="ml-4 mr-2"
            color="accent"
            depressed
            small
          >සෙවීම ඉවත් කරන්න</v-btn>
        </v-col>
      </v-row>
    </header>

     <v-expansion-panels accordion multiple>
      <v-expansion-panel class="custom-shadow">
        <v-expansion-panel-header>
          සොයන්න
          
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col cols="12" md="3" sm="6">
              <v-text-field
                 
                @change="fetchSuspendedMembers"
                background-color="#fafafa"
                class="mx-1 my-1"
                clearable
                dense
                flat
                hide-details
                label="සාමාජික අංකය"
                solo
                v-model="queryParams.username"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-text-field
                 
                @change="fetchSuspendedMembers"
                background-color="#fafafa"
                class="mx-1 my-1"
                clearable
                dense
                flat
                hide-details
                label="සම්පූර්ණ නම"
                solo
                v-model="queryParams.fullName"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-select
                 
                :items="['ස්ත්‍රී', 'පුරුෂ']"
                @change="fetchSuspendedMembers"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                label="ස්ත්‍රී/පුරුෂ"
                solo
                v-model="queryParams.gender"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-select
                 
                :items="queryData.marriageStatuses"
                @change="fetchSuspendedMembers"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                item-text="status"
                label="විවාහක තත්වය"
                solo
                v-model="queryParams.marriageStatus"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-select
                 
                :items="queryData.countries"
                @change="fetchSuspendedMembers"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                item-text="name"
                label="ජීවත්වන රට"
                solo
                v-model="queryParams.livingCountry"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-select
                 
                :items="queryData.districts"
                @change="fetchSuspendedMembers"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                item-text="name"
                label="දිස්ත්‍රික්කය"
                solo
                v-model="queryParams.district"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-row no-gutters>
                <v-col cols="6">
                  <v-select
                     
                    :items="queryData.heights"
                    @change="fetchSuspendedMembers"
                    background-color="#fafafa"
                    class="mx-1 my-1"
                    clearable
                    dense
                    flat
                    hide-details
                    item-text="value"
                    label="උස(සිට)"
                    solo
                    v-model="queryParams.heightFrom"
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-select
                     
                    :items="queryData.heights"
                    @change="fetchSuspendedMembers"
                    background-color="#fafafa"
                    class="mx-1 my-1"
                    clearable
                    dense
                    flat
                    hide-details
                    item-text="value"
                    label="උස(දක්වා)"
                    solo
                    v-model="queryParams.heightTo"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-select
                 
                :items="queryData.religions"
                @change="fetchSuspendedMembers"
                background-color="#fafafa"
                class="mx-1 my-1"
                clearable
                dense
                flat
                hide-details
                item-text="name"
                label="ආගම"
                solo
                v-model="queryParams.religion"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-select
                 
                :items="queryData.races"
                @change="fetchSuspendedMembers"
                background-color="#fafafa"
                class="mx-1 my-1"
                clearable
                dense
                flat
                hide-details
                item-text="name"
                label="ජාතිය"
                solo
                v-model="queryParams.race"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-select
                 
                :items="queryData.casts"
                @change="fetchSuspendedMembers"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                item-text="name"
                label="කුළය"
                solo
                v-model="queryParams.cast"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-select
                 
                :items="queryData.educationLevels"
                @change="fetchSuspendedMembers"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                item-text="level"
                label="අධ්‍යාපන මට්ටම"
                solo
                v-model="queryParams.educationLevel"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-select
                 
                :items="queryData.jobs"
                @change="fetchSuspendedMembers"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                item-text="name"
                label="රැකියා අංශය"
                solo
                v-model="queryParams.job"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-select
                 
                :items="queryData.monthlyIncomes"
                @change="fetchSuspendedMembers"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                item-text="value"
                label="මාසික ආදායම"
                solo
                v-model="queryParams.monthlyIncome"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-row no-gutters>
                <v-col>
                  <v-select
                     
                    :items="queryData.numbersArray"
                    @change="fetchSuspendedMembers"
                    background-color="#fafafa"
                    class="my-1 mx-1"
                    clearable
                    dense
                    flat
                    hide-details
                    label="වයස(සිට)"
                    solo
                    v-model="queryParams.ageFrom"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-select
                     
                    :items="queryData.numbersArray"
                    @change="fetchSuspendedMembers"
                    background-color="#fafafa"
                    class="my-1 mx-1"
                    clearable
                    dense
                    flat
                    hide-details
                    label="වයස(දක්වා)"
                    solo
                    v-model="queryParams.ageTo"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel class="custom-shadow" id="secondary-filter">
        <v-expansion-panel-header>තව දුරටත් සොයන්න</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col cols="6" md="2" sm="4">
              <v-select
                 
                :items="queryData.lagna"
                @change="fetchSuspendedMembers"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                item-text="name"
                label="ලග්නය"
                outlined
                v-model="queryParams.lagnaya"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                 
                :items="queryData.gana"
                @change="fetchSuspendedMembers"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                item-text="name"
                label="ගණය"
                outlined
                v-model="queryParams.ganaya"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                 
                :items="queryData.nekath"
                @change="fetchSuspendedMembers"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                item-text="name"
                label="නැකත"
                outlined
                v-model="queryParams.nekatha"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                 
                :items="queryData.oneToTwelve"
                @change="fetchSuspendedMembers"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                label="රවි"
                outlined
                v-model="queryParams.ravi"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                 
                :items="queryData.oneToTwelve"
                @change="fetchSuspendedMembers"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                label="චන්ද්‍ර"
                outlined
                v-model="queryParams.chandra"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                 
                :items="queryData.oneToTwelve"
                @change="fetchSuspendedMembers"
                background-color="#fafafa"
                class="mx-1 my-1"
                clearable
                dense
                flat
                hide-details
                label="කුජ"
                outlined
                v-model="queryParams.kuja"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                 
                :items="queryData.oneToTwelve"
                @change="fetchSuspendedMembers"
                background-color="#fafafa"
                class="mx-1 my-1"
                clearable
                dense
                flat
                hide-details
                label="බුධ"
                outlined
                v-model="queryParams.budha"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                 
                :items="queryData.oneToTwelve"
                @change="fetchSuspendedMembers"
                background-color="#fafafa"
                class="mx-1 my-1"
                clearable
                dense
                flat
                hide-details
                label="ගුරු"
                outlined
                v-model="queryParams.guru"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                 
                :items="queryData.oneToTwelve"
                @change="fetchSuspendedMembers"
                background-color="#fafafa"
                class="mx-1 my-1"
                clearable
                dense
                flat
                hide-details
                label="රාහු"
                outlined
                v-model="queryParams.rahu"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                 
                :items="queryData.oneToTwelve"
                @change="fetchSuspendedMembers"
                background-color="#fafafa"
                class="mx-1 my-1"
                clearable
                dense
                flat
                hide-details
                label="සිකුරු"
                outlined
                v-model="queryParams.sikuru"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                 
                :items="queryData.oneToTwelve"
                @change="fetchSuspendedMembers"
                background-color="#fafafa"
                class="mx-1 my-1"
                clearable
                dense
                flat
                hide-details
                label="ශනි"
                outlined
                v-model="queryParams.shani"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                 
                :items="queryData.oneToTwelve"
                @change="fetchSuspendedMembers"
                background-color="#fafafa"
                class="mx-1 my-1"
                clearable
                dense
                flat
                hide-details
                label="කේතු"
                outlined
                v-model="queryParams.kethu"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                 
                :items="['ඔව්', 'නැත', 'නොදනී']"
                @change="fetchSuspendedMembers"
                background-color="#fafafa"
                class="mx-1 my-1"
                clearable
                dense
                flat
                hide-details
                label="පාප කේන්දර"
                outlined
                v-model="queryParams.papaKendara"
              ></v-select>
            </v-col>
            <v-col class="d-flex justify-end" cols="6" md="10" sm="8">
              <!-- <v-switch
                @change="fetchHoroscopeEmptyMembers"
                class="mt-3"
                dense
                inset
                v-model="horoscopeEmptyList"
              >
                <template slot="label" v-if="!!$vuetify.breakpoint.mdAndUp">
                  <span class="body-2">කේන්දර හිස් සාමාජිකයන් පමණක් පෙන්වන්න</span>
                </template>
              </v-switch> -->
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-data-table
      :footer-props="$store.state.dataTableFooterProps"
      :headers="headers"
      :items="members"
      :loading="loading"
      :mobile-breakpoint="768"
      :options.sync="options"
      :server-items-length="total"
      @click:row="(member) => $router.push(`/aw-admin/suspended-members/${member.id}`)"
      class="custom-shadow custom-radius mt-5"
      show-expand
    >
      <template slot="no-data">ලබා ගත හැකි දත්ත නැත</template>
      <!-- <template v-slot:top>
        <v-text-field
          @change="fetchSuspendedMembers"
          class="mx-4"
          clearable
          label="සාමාජික අංකයෙන් සොයන්න"
          single-line
          type="number"
          v-model="search"
        ></v-text-field>
      </template> -->
      <template v-slot:item.action="{ item }">
        <v-btn @click.stop="approve(item)" class="mr-2" color="success" depressed small>අනුමත කරන්න</v-btn>
        <v-btn @click.stop="remove(item)" color="error" depressed small>ඉවත් කරන්න</v-btn>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">{{ item.suspensionReason }}</td>
      </template>
    </v-data-table>

    <ConfirmDialog ref="confirm" />
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import { CHANGE_LOADING_STATE, SHOW_ALERT } from "../../store/mutation-types";
import { ApiService } from "../../services/api.service";
import ConfirmDialog from "../../components/ConfirmDialog";


const initialQueryParams = {
  username: "",
  fullName:"",
  gender: "",
  marriageStatus: "",
  livingCountry: "",
  heightFrom: "",
  heightTo: "",
  race: "",
  religion: "",
  cast: "",
  monthlyIncome: "",
  district: "",
  educationLevel: "",
  job: "",
  lagnaya: "",
  ganaya: "",
  nekatha: "",
  ageFrom: "",
  ageTo: "",
  ravi: "",
  chandra: "",
  kuja: "",
  budha: "",
  guru: "",
  rahu: "",
  sikuru: "",
  shani: "",
  kethu: "",
  papaKendara: "",
}

export default {
  name: "suspendedMembers",

  components: {
    ConfirmDialog
  },

  data: () => ({
    mount: false,
    headers: [
      { text: "සාමාජික අංකය", value: "id", sortable: false },
      { text: "ලියාපදිංචි වූ දිනය", value: "created_at" },
      { text: "අත්හිටවූ දිනය", value: "suspendedDate" },
      { text: "හේතුව", value: "data-table-expand", sortable: false },
      { text: "ක්‍රියා", value: "action", align: "right", sortable: false }
    ],
    search: '',
    options: {},
    total: 0,
    loading: false,
    members: [],
    queryData: {
      marriageStatuses: [],
      countries: [],
      heights: [],
      // races: [],
      religions: [],
      casts: [],
      monthlyIncomes: [],
      // districts: [],
      educationLevels: [],
      jobs: [],
      lagna: [],
      gana: [],
      nekath: [],
      numbersArray: [
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
        53,
        54,
        55,
        56,
        57,
        58,
        59,
        60,
        61,
        62,
        63,
        64,
        65,
        66,
        67,
        68,
        69,
        70
      ],
      oneToTwelve: ["-", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]
      // smokeUsages: [],
      // foodHabbits: [],
      // countries: []
    },
    queryParams: JSON.parse(JSON.stringify(initialQueryParams)),
  }),

  beforeMount: function () {
    this.mount = true;
  },

  async mounted() {
    this.$store.commit(CHANGE_LOADING_STATE, { active: true });
    await this.fetchSuspendedMembers();
    await this.fetchQueryData();
    this.$store.commit(CHANGE_LOADING_STATE, { active: false });
  },

  activated: function () {
    if (!this.mount) { // Will call only when page is not mounting
      this.fetchSuspendedMembers();
    }
  },

  deactivated: function () {
    this.mount = false; // Reset mount variable when page is deactivating
  },

  watch: {
    options: {
      handler: function () {
        this.fetchSuspendedMembers();
      },
      deep: true,
    }
  },

   computed: {
    getFilterQuery: function () {
      const usernameParam = this.queryParams.username
        ? { id: this.queryParams.username }
        : {};
      const fullNameParam = this.queryParams.fullName
        ? { fullName: this.queryParams.fullName }
        : {};
      const genderParam = this.queryParams.gender
        ? { gender: this.queryParams.gender }
        : {};

      const marriageStatusParam = this.queryParams.marriageStatus
        ? { marriageStatus: this.queryParams.marriageStatus }
        : {};

      const livingCountryParam = this.queryParams.livingCountry
        ? { livingCountry: this.queryParams.livingCountry }
        : {};

      const heightFromParam = this.queryParams.heightFrom
        ? { heightFrom: this.queryParams.heightFrom }
        : {};

      const heightToParam = this.queryParams.heightTo
        ? { heightTo: this.queryParams.heightTo }
        : {};

      const raceParam = this.queryParams.race
        ? { race: this.queryParams.race }
        : {};

      const religionParam = this.queryParams.religion
        ? { religion: this.queryParams.religion }
        : {};

      const castParam = this.queryParams.cast
        ? { cast: this.queryParams.cast }
        : {};

      const monthlyIncomeParam = this.queryParams.monthlyIncome
        ? { monthlyIncome: this.queryParams.monthlyIncome }
        : {};

      const districtParam = this.queryParams.district
        ? { district: this.queryParams.district }
        : {};

      const educationLevelParam = this.queryParams.educationLevel
        ? { educationLevel: this.queryParams.educationLevel }
        : {};

      const jobParam = this.queryParams.job
        ? { job: this.queryParams.job }
        : {};

      const lagnaParam = this.queryParams.lagnaya
        ? { lagna: this.queryParams.lagnaya }
        : {};

      const ganayaParam = this.queryParams.ganaya
        ? { ganaya: this.queryParams.ganaya }
        : {};

      const nekathaParam = this.queryParams.nekatha
        ? { nekatha: this.queryParams.nekatha }
        : {};

      const ageFromParam = this.queryParams.ageFrom
        ? { ageFrom: this.queryParams.ageFrom }
        : {};

      const ageToParam = this.queryParams.ageTo
        ? { ageTo: this.queryParams.ageTo }
        : {};

      const raviParam = this.queryParams.ravi
        ? { ravi: this.queryParams.ravi }
        : {};

      const chandraParam = this.queryParams.chandra
        ? { chandra: this.queryParams.chandra }
        : {};

      const kujaParam = this.queryParams.kuja
        ? { kuja: this.queryParams.kuja }
        : {};

      const budhaParam = this.queryParams.budha
        ? { budha: this.queryParams.budha }
        : {};

      const guruParam = this.queryParams.guru
        ? { guru: this.queryParams.guru }
        : {};

      const rahuParam = this.queryParams.rahu
        ? { rahu: this.queryParams.rahu }
        : {};

      const sikuruParam = this.queryParams.sikuru
        ? { sikuru: this.queryParams.sikuru }
        : {};

      const shaniParam = this.queryParams.shani
        ? { shani: this.queryParams.shani }
        : {};

      const kethuParam = this.queryParams.kethu
        ? { kethu: this.queryParams.kethu }
        : {};

      const papaKendaraParam = this.queryParams.papaKendara
        ? { papaKendara: this.queryParams.papaKendara }
        : {};

      const params = Object.assign(
        usernameParam,
        fullNameParam,
        genderParam,
        marriageStatusParam,
        livingCountryParam,
        heightFromParam,
        heightToParam,
        religionParam,
        raceParam,
        castParam,
        monthlyIncomeParam,
        districtParam,
        educationLevelParam,
        jobParam,
        lagnaParam,
        ganayaParam,
        nekathaParam,
        ageFromParam,
        ageToParam,
        raviParam,
        chandraParam,
        kujaParam,
        budhaParam,
        guruParam,
        rahuParam,
        sikuruParam,
        shaniParam,
        kethuParam,
        papaKendaraParam
      );

      return params;
    }
  },

  methods: {
    ...mapMutations({ showAlert: SHOW_ALERT }),

    // Fetch suspended member list
    fetchSuspendedMembers: async function () {
      this.loading = true;

      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const order = sortDesc[0] ? 'desc' : 'asc';
      const pageOptions = itemsPerPage != -1 ? { perPage: itemsPerPage, page: page } : {};
      const sortOptions = sortBy.length != 0 ? { sortBy: sortBy[0], order: order } : {};
      const memberId = this.search ? this.search : null;

      try {
        const url = "customers";
        const params = { params: { approved: false, id: memberId, ...pageOptions, ...sortOptions, ...this.getFilterQuery } };
        const response = await ApiService.get(url, params);
        this.members = response.data.data;
        this.total = response.data.meta.total;
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },

    // Approve a member
    approve: async function (member) {
      if (await this.$refs.confirm.open('අනුමත කරන්න', 'ඔබට විශ්වාසද ?', { color: 'success' })) {
        this.loading = true;
        try {
          const url = 'customers/approve';
          const data = { approved: true, customer: member.id };
          const response = await ApiService.put(url, data);
          const index = this.members.indexOf(member);
          this.members.splice(index, 1);
          this.showAlert({ message: "ගිණුම අනුමත කරන ලදි", type: "success" });
        } catch (error) {
          console.log(error);
        }
        this.loading = false;
      }
    },

    // Delete a member
    remove: async function (member) {
      if (await this.$refs.confirm.open('ඉවත් කරන්න', 'ඔබට විශ්වාසද ?', { color: 'error' })) {
        this.loading = true;
        try {
          const url = `customers/delete/${member.id}`;
          const response = await ApiService.delete(url);
          const index = this.members.indexOf(member);
          this.members.splice(index, 1);
          this.showAlert({ message: "ගිණුම ඉවත් කරන ලදි", type: "success" });
        } catch (error) {
          console.log(error);
        }
        this.loading = false;
      }
    },

    // Fetch data for query
    fetchQueryData: async function () {
      try {
        const url = "metadata";
        const response = await ApiService.get(url);
        this.queryData.marriageStatuses = response.data.marriageStatuses.slice(1);
        this.queryData.countries = response.data.countries;
        this.queryData.heights = response.data.heights;
        this.queryData.religions = response.data.religions.slice(1);
        this.queryData.races = response.data.races.slice(1);
        this.queryData.casts = response.data.casts.slice(1);
        this.queryData.districts = response.data.districts.slice(1);
        this.queryData.educationLevels = response.data.educations.slice(1);
        this.queryData.jobs = response.data.jobs.slice(1);
        this.queryData.monthlyIncomes = response.data.monthlyIncomes.slice(1);
        this.queryData.lagna = response.data.lagna;
        this.queryData.gana = response.data.gana;
        this.queryData.nekath = response.data.nekath;
      } catch (error) {
        console.log(error);
      }
    },
    
    // Clear all filters
    clarFilters : async function () {
      this.queryParams = JSON.parse(JSON.stringify(initialQueryParams));
      this.fetchSuspendedMembers();
    }
  }
}
</script>